import React, { useContext, createContext } from 'react'

//Context
export const AppContext = createContext(null)

export interface GlobalContext {
  clientName: string
}

interface AppContextProvider {
  children: React.ReactNode
  clientName: string
}

//Provider
export const AppContextProvider: React.FC<AppContextProvider> = ({
  children,
  clientName,
}) => {
  const [variableState, setVariableState] = React.useState<GlobalContext>({
    clientName,
  })

  const values = React.useMemo(
    () => ({ variableState, setVariableState }),
    [variableState]
  )

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export function useAppContext(): any {
  const context = useContext(AppContext)

  if (!context) {
    console.error('Error deploying App Context!!!')
  }

  return context
}

export default useAppContext
